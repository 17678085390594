.Home {
  background-color: white;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dashboard {
  background-color: white;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Header {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logo{
  width: 70px;
  height: 70px;
}

.WalletAddressHeading {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.LogoTitle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.LogoTitle p{
  font-weight: bold;
  font-size: 25px;
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #000000;  /* Crna boja */
}

.WalletAddress {
  width: 40%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  color: #000000;  /* Crna boja */
}

.WalletAddress > div {
  color: black;
  width: 80%;
  word-wrap: break-word;
}

.Heading {
  background-color: #000000;  /* Crna boja */
  color: white;  /* Bijeli tekst */
  padding: 10px;
  border-radius: 5px;
}

.Button {
  height: 50px;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  background-color: #000000;  /* Crna boja */
  color: white;  /* Bijeli tekst */
  border: none;
}

.Button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.LaunchToken {
  width: 50%;
  display: 'flex';
  flex-direction: 'column';
  gap: 10px;
  margin: auto;
  padding: 30px;
}

@media (max-width: 600px) {
  .LaunchToken {
    width: 80%;
  }
}

.LaunchToken div:nth-of-type(2){
  display: flex;
  justify-content: flex-end;
}

.SubmitButton {
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  background-color: #000000;  /* Crna boja */
  color: white;  /* Bijeli tekst */
  border: none;
}

Button:hover {
  cursor: pointer;
  border: #8f9196 solid 1px;
}

.form{
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 25px; /* space between items */
  margin-bottom: 15px;
}

input {
  width: 100%;
  border: solid 1px gray;
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
}

input.error {
  border: solid 2px red;
}

input:focus {
  border-color: black; /* Change border color on focus */
  outline: none; /* Remove default outline on focus */
}

.InputFieldError{
  color: red;
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
}

.InputFieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.InputFieldHeading {
  display: flex;
  justify-content: space-between;
}

.InputFieldBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background: 
    radial-gradient(farthest-side,#000000 95%,#0000) 50% 0/12px 12px no-repeat,  /* Crna boja */
    radial-gradient(farthest-side,#0000 calc(100% - 5px),#000000 calc(100% - 4px)) content-box;  /* Crna boja */
  animation: l6 2s infinite ;
}

@keyframes l6 {to{transform: rotate(1turn)}}

.deployedTokenText{
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 600px){
  .navbar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px){
  .LogoTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
}

@media (max-width: 600px){
  .WalletAddressHeading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
}

@media (max-width: 600px) {
  .form {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }
}

@media (max-width: 700px) {
  .Header {
    display: flex;
    flex-direction: column;
    gap: 20px
  }
}

@media (max-width: 600px) {
  .WalletAddress {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.walletBalance{
  display: flex;
  gap: 10px;
}

.walletBalance p {
  font-weight: bold;
  font-size: 20px;
}


table {
  width: 100%; 
  border-collapse: collapse; 
  margin: 20px 0; 
  font-family: Arial, sans-serif; 
}

th {
  background-color: #000000;  /* Crna boja */
  color: white;  /* Bijeli tekst */
  padding: 15px 18px; 
  text-align: left; 
  font-size: 16px; 
}

td {
  padding: 15px 18px; 
  text-align: left;
  border-bottom: 1px solid #ddd; 
}

tr:nth-child(even) {
  background-color: #f2f2f2; 
}

tr:hover {
  background-color: #ddd; 
}
